
#map {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
}

.ol-mouse-position {
    position: absolute;
    top: unset;
    right: unset;
    bottom: 8px;
    left: 8px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    border-radius: 2px;
    font: 12px sans-serif;
    color: var(--ol-subtle-foreground-color);
    background-color: var(--ol-background-color);
}
